import Vue from 'vue';
import Vuex from 'vuex';

import { debounce } from 'lodash';

import personnel from './api/personnel'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    personnel: [],
    filters: [],
    sort: {
      key: 'name',
      order: 1
    }
  },
  actions: {

    fetchPersonnel({ commit, state }) {

      // make query
      const query = state.filters
        .reduce((memo, group) => {

          // collect selected items id
          const selected_ids = group.choices
            .filter(item => item.selected)
            .map(item => item.id)

          // discard group if nothing is selected
          if (selected_ids.length === 0) {
            return memo
          }

          // find operator
          let op = 'or'
          if (['and', 'or'].includes(group.operator)) {
            op = group.operator
          }

          // store group filter
          memo.push({
            key: group.key,
            op: op,
            values: selected_ids,
          })
          return memo
        }, [])

      // fetch personnel
      personnel.getPersonnel(query, state.sort)
        .then(response => {
          commit('setPersonnel', response.data)
        })
    },

    initFilters({ commit, dispatch }, _filters) {
      const filters = _filters.map(group => {

        // add operator toggle to specializations
        if (group.key === 'specializations_1') {
          group.operator = 'and';
        }

        // add selected properties
        group.choices.forEach(item => {
          item.selected = false;
        });

        return group;
      });

      commit('setFilters', filters)
      commit('updateFilters')
      dispatch('fetchPersonnel')
    },

    updateFilters: debounce(({ commit, dispatch }) => {
      commit('updateFilters')
      dispatch('fetchPersonnel')
    }, 500, { maxWait: 3000 }),

    updateOrder({ commit, dispatch }, sort) {
      commit('setOrder', sort)
      dispatch('fetchPersonnel')
    },

    reset({ commit, dispatch, state }) {
      // reset order
      const sort = { key: 'name', order: 1 }

      // reset filters
      const filters = state.filters.map(group => {
        if (group.operator != undefined) {
          group.operator = 'and'
        }
        group.choices.forEach(item => {
          item.selected = false
        })
        return group
      })

      commit('setOrder', sort)
      commit('setFilters', filters)
      commit('updateFilters')
      dispatch('fetchPersonnel')
    },

  },
  mutations: {

    setFilters(state, filters) {
      state.filters = filters
    },

    updateFilters(state) {

      // force status to "active" if nothing selected
      const i = state.filters.findIndex(group => group.key === 'status');
      if (i !== -1) {

        // find if something is selected
        if (state.filters[i].choices.filter(item => item.selected).length === 0) {

          // select "active" filter
          const j = state.filters[i].choices.findIndex(item => item.id === 2);
          if (j !== -1) {
            state.filters[i].choices[j].selected = true
          }
        }
      }
    },

    setOrder(state, { key, order }) {
      state.sort = { key, order }
    },

    setPersonnel(state, personnel) {
      state.personnel = personnel.map(p => {

        // add meta properties to each row
        p._meta = {
          selected: false,
        }

        return p
      });
    },

  },
});
