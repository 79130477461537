const maxMailtoCharacters = 1900;
const maxMailtoRecipients = 50;

const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

function generateMailtos(emails) {
  const base = 'mailto:?bcc=';
  const sep = isMacLike ? ',' : ';' ;

  let chunks = [];
  let idx = 0;
  while (true) {
    if (idx >= emails.length) {
      return chunks;
    }

    let mailto = base + emails[idx];
    idx += 1;
    let count = 1;
    while (true) {
      if (idx >= emails.length || mailto.length + emails[idx].length >= maxMailtoCharacters || count >= maxMailtoRecipients) {
        chunks.push(mailto);
        break;
      }
      mailto += sep + emails[idx];
      idx += 1;
      count += 1;
    }
  }
}

export default generateMailtos
