import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['sidebar']

  connect() {
    window.addEventListener('keyup', this._onKeyup)
  }

  disconnect() {
    window.removeEventListener('keyup', this._onKeyup)
  }

  open() {
    this._open()
  }

  close() {
    this._close()
  }

  toggle() {
    if (this.sidebarTarget.classList.contains('md:block')) {
      this.sidebarTarget.classList.remove('md:block')
      this.sidebarTarget.classList.add('md:hidden')
    }
    else {
      this.sidebarTarget.classList.remove('md:hidden')
      this.sidebarTarget.classList.add('md:block')
    }
  }

  clickOnBackground(ev) {
    if (ev.target === this.sidebarTarget) {
      this._close();
    }
  }

  _open() {
    this.sidebarTarget.classList.remove('hidden')
    this._lockScroll()
  }

  _close() {
    this.sidebarTarget.classList.add('hidden')
    this._unlockScroll()
  }

  _onKeyup = ev => {
    if (ev.keyCode === 27 && !this.sidebarTarget.classList.contains('hidden')) {
      this._close();
    }
  }

  _lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Save the scroll position
    this._saveScrollPosition();

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-0', 'overflow-hidden', 'max-h-screen', 'lg:static', 'lg:max-h-full', 'lg:overflow-visible')

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  _unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-0', 'overflow-hidden', 'max-h-screen', 'lg:static', 'lg:max-h-full', 'lg:overflow-visible')

    // Restore the scroll position of the body before it got locked
    this._restoreScrollPosition();

    // Remove the negative top inline style from body
    document.body.style.top = null;
  }

  _saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  _restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
  }
}
