<template>
  <div>
    <div v-for="group in filters" :key="group.id" class="mb-3">

      <h4 class="font-semibold text-base mb-2 inline-flex items-baseline">
        {{ group.title }}
        <select v-if="group.operator" v-model="group.operator" @change="updateFilters()" class="text-sm font-normal bg-primary text-white border-0 appearance-normal hover:bg-primary-darker focus:bg-primary-darker focus:ring-0 focus:outline-none rounded cursor-pointer ml-2 pl-2 pr-1 py-0.5 bg-none">
          <option value="and">ET</option>
          <option value="or">OU</option>
        </select>
      </h4>

      <div v-for="choice in group.choices" :key="choice.id">
        <label class="inline-flex items-center cursor-pointer">
          <input type="checkbox" class="form-checkbox" v-model="choice.selected" @change="updateFilters()">
          <span class="text-sm ml-2" :class="{ 'italic': choice.id == -1 }">{{ choice.title }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'filters'
    ])
  },
  methods: {
    ...mapActions([
      'updateFilters'
    ])
  }
}
</script>
