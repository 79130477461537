<template>
  <div class="flex justify-between bg-gray-100 mb-3 lg:mb-4 px-2 py-2" >
    <div class="flex">
      <div>
        <button data-action="click->sidebar#open" class="md:hidden btn flex items-center h-full py-1.5 px-3 bg-white hover:bg-gray-50 focus:bg-gray-50 border border-gray-200 font-normal text-gray-600" data-turbo="false">
          <inline-svg :src="require('images/heroicons/outline/filter.svg')" class="h-4 w-4 flex-shrink-0"></inline-svg>
          <span class="hidden lg:inline ml-1">Filtres</span>
        </button>
        <button data-action="click->sidebar#toggle" class="hidden md:flex btn items-center h-full py-1.5 px-3 bg-white hover:bg-gray-50 focus:bg-gray-50 border border-gray-200 font-normal text-gray-600" data-turbo="false">
          <inline-svg :src="require('images/heroicons/outline/filter.svg')" class="h-4 w-4 flex-shrink-0"></inline-svg>
          <span class="hidden lg:inline ml-1">Filtres</span>
        </button>
      </div>
      <div class="ml-1">
        <button @click="reset()" class="btn flex items-center h-full py-1.5 px-3 bg-white hover:bg-gray-50 focus:bg-gray-50 border border-gray-200 font-normal text-gray-600" data-turbo="false">
          <inline-svg :src="require('images/custom-icons/reset.svg')" class="h-4 w-4 flex-shrink-0"></inline-svg>
          <span class="hidden lg:inline ml-1">Réinitialiser</span>
        </button>
      </div>
    </div>
    <div class="flex">
      <div data-controller="dropdown" class="relative h-full">

        <button data-action="click->dropdown#toggle click@window->dropdown#hide" class="btn flex items-center h-full py-1.5 px-3 bg-white hover:bg-gray-50 focus:bg-gray-50 border border-gray-200 font-normal text-gray-600">
          <span class="mr-1" v-if="count > 1">{{ count }} personnes sélectionnées</span>
          <span class="mr-1" v-else>{{ count }} personne sélectionnée</span>
          <inline-svg :src="require('images/heroicons/solid/chevron-down.svg')" class="h-4 w-4 flex-shrink-0"></inline-svg>
        </button>

        <div data-dropdown-target="menu" class="absolute right-0 mt-1 bg-white rounded shadow hidden w-56">
          <div class="py-1">

            <div v-if="mailtoLinks.length > 1" data-controller="modal" data-modal-allow-background-close="true">
              <button data-action="click->modal#open" class="block w-full text-left px-4 py-2 font-normal text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:text-gray-900 hover:no-underline focus:no-underline focus:outline-none">Envoyer un email</button>

              <div data-modal-target="container" data-action="click->modal#closeBackground keyup@window->modal#closeWithKeyboard" class="hidden fixed inset-0 overflow-y-auto flex items-center justify-center" style="z-index: 9999;">
                <div class="max-h-screen w-full max-w-lg relative">
                  <div class="m-1 bg-white rounded shadow">
                    <div class="p-4 lg:p-5">
                      <h2>Envoyer un e-mail groupé</h2>
                      <p>En raison de limitations du nombre de destinataires par email, la liste des adresses a été découpée en plusieurs groupes.</p>
                      <p class="mt-2">Cliquez sur chacun des liens ci-dessous pour créer tous les messages nécessaires. Veuillez à bien envoyer votre email à chacun de ces groupes.</p>
                      <ul class="mt-4">
                        <li v-for="(link, i) in mailtoLinks" v-bind:key="link" class="my-1"><a class="text-md font-bold text-gray-900" :href="link">Groupe N° {{ i+1 }}</a></li>
                      </ul>
                      <div class="flex justify-end items-center flex-wrap mt-3">
                        <button class="btn btn-primary" data-action="click->modal#close">Fermer</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a v-else :href="mailtoLinks[0]" class="block w-full text-left px-4 py-2 font-normal text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:text-gray-900 hover:no-underline focus:no-underline focus:outline-none" data-turbo="false">Envoyer un email</a>

          </div>
          <div class="border-t border-gray-200"></div>
          <div class="py-1">
            <a class="block w-full text-left px-4 py-2 font-normal text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:text-gray-900 hover:no-underline focus:no-underline focus:outline-none" :href="excelUrl" data-turbo="false">Exporter un fichier Excel</a>
            <a class="block w-full text-left px-4 py-2 font-normal text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:text-gray-900 hover:no-underline focus:no-underline focus:outline-none" :href="vcardsUrl" data-turbo="false">Exporter les vCards</a>
          </div>
        </div>

      </div>
      <div class="ml-1" v-if="canAddPersonnel">
        <a :href="addPersonnelUrl" class="btn btn-primary flex items-center h-full py-1.5 px-3 font-normal" data-turbo="false">
          <inline-svg :src="require('images/heroicons/solid/user-add.svg')" class="h-4 w-4 flex-shrink-0"></inline-svg>
          <span class="hidden lg:inline ml-1">Ajouter une personne</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions,  mapState } from 'vuex'
import generateMailtos from '../../../lib/mailto'

export default {
  methods: {
    ...mapActions([
      'reset'
    ])
  },
  computed: {
    ...mapState([
      'personnel',
    ]),
    selectedPersonnel() {
      return this.personnel.filter(p => p._meta.selected);
    },
    count() {
      return this.selectedPersonnel.length;
    },
    mailtoLinks() {
      const emails = this.selectedPersonnel.map(p => p.email);
      if (emails.length == 0) {
        return [];
      }
      return generateMailtos(emails);
    },
    ids() {
      return this.selectedPersonnel.map(p => p.id);
    },
    excelUrl() {
      return this.ids.length > 0 ? Routes.personnel_export_excel_path({ 'ids': this.ids.join(',') }) : null;
    },
    vcardsUrl() {
      return this.ids.length > 0 ? Routes.personnel_export_vcards_path({ 'ids': this.ids.join(',') }) : null;
    },
    canAddPersonnel() {
      return gon.permissions.includes('personnel:create');
    },
    addPersonnelUrl() {
      return Routes.new_personnel_path();
    },
  },
}
</script>
