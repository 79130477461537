<template>
  <div class="flex pt-3 lg:pt-4 pb-4 lg:pb-6" data-controller="sidebar">

    <div data-sidebar-target="sidebar" data-action="click->sidebar#clickOnBackground touch->sidebar#clickOnBackground" class="fixed z-30 md:z-0 inset-0 flex-none h-full bg-black bg-opacity-25 w-full md:bg-white md:static md:h-auto md:overflow-y-visible md:w-auto hidden md:block">
      <div class="h-full md:border-r overflow-y-auto mr-36 md:mr-0 md:block md:relative md:bg-transparent overflow-hidden bg-white px-3 lg:px-4 text-md md:text-base">
        <div class="px-2 py-4 md:px-0 md:py-0">
          <Filters :filters="filters" />
        </div>
      </div>
    </div>

    <div class="flex-auto w-0 px-3 lg:px-4">
      <Toolbar />
      <Listing />
    </div>
  </div>
</template>

<script>
import Listing from './Listing.vue'
import Filters from './Filters.vue'
import Toolbar from './Toolbar.vue'

export default {
  props: [ 'filters' ],
  components: { Filters, Listing, Toolbar },
}
</script>
