import Vue from 'vue';
import TurbolinksAdapter from 'vue-turbolinks';
import InlineSvg from 'vue-inline-svg';

import App from './components/App.vue'
import store from './store'

Vue.use(TurbolinksAdapter)
Vue.component('inline-svg', InlineSvg);

document.addEventListener('turbo:load', () => {
  const element = document.getElementById('js-personnel-app');
  if (element != null) {
    const app = new Vue({
      el: element,
      store,
      beforeCreate() {
        const filters = gon.personnel_filters;
        if (filters != null) {
          this.$store.dispatch('initFilters', filters);
        }
      },
      render: h => h(App),
    });
  }
});
