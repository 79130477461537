<template>
  <div class="table-responsive">
    <table class="table table-bordered" v-if="personnel.length">
      <thead>
        <tr class="table-head">
          <th>
            <input type="checkbox" class="form-checkbox" :checked="selectAll.checked" :indeterminate.prop="selectAll.indeterminate" v-on:change="ev => toggleSelectAll(ev.target.checked)">
          </th>
          <th></th>
          <th v-on:click="sortBy('rank')" class="sortable whitespace-nowrap">
            Grade
            <span class="arrow" :class="{ asc: sort.key === 'rank' && sort.order > 0, desc: sort.key === 'rank' && sort.order < 0 }"></span>
          </th>
          <th v-on:click="sortBy('name')" class="sortable whitespace-nowrap">
            Nom, Prénom
            <span class="arrow" :class="{ asc: sort.key === 'name' && sort.order > 0, desc: sort.key === 'name' && sort.order < 0 }"></span>
          </th>
          <th v-on:click="sortBy('ois_count')" class="sortable whitespace-nowrap">
            #OI
            <span class="arrow" :class="{ asc: sort.key === 'ois_count' && sort.order > 0, desc: sort.key === 'ois_count' && sort.order < 0 }"></span>
          </th>
          <th>Organe(s) d'intervention</th>
          <th v-on:click="sortBy('city')" class="sortable whitespace-nowrap">
            Localité
            <span class="arrow" :class="{ asc: sort.key === 'city' && sort.order > 0, desc: sort.key === 'city' && sort.order < 0 }"></span>
          </th>
          <th></th>
          <th>Téléphone</th>
          <th>E-mail</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="person in personnel" :key="person.id" :class="{ 'bg-blue-50': person._meta.selected }">
          <td>
            <input type="checkbox" class="form-checkbox" v-model="person._meta.selected">
          </td>
          <td>
            <div class="min-w-max">
              <img v-if="person.rank_icon" :src="person.rank_icon" class="w-5 h-5" />
            </div>
          </td>
          <td>{{ person.rank }}</td>
          <td><a :href="Routes.personnel_path(person.id)">{{ person.full_name }}</a></td>
          <td>{{ person.ois_count }}</td>
          <td>
            <template v-for="oi in person.ois">
              {{ oi }}<br :key="oi">
            </template>
          </td>
          <td>
            <template v-if="person.address != null">{{ person.address.city }}</template>
          </td>
          <td>
            <a v-if="person.address != null" :href="`https://www.google.com/maps/place/${person.address.street}+${person.address.street_no}+${person.address.city}+${person.address.zipcode}+${person.address.country}`" target="_blank">
              <inline-svg :src="require('images/custom-icons/location.svg')" class="text-gray-800 w-5 h-5"></inline-svg>
            </a>
          </td>
          <td class="whitespace-nowrap"><a :href="`tel:${person.phone}`" class="font-normal" v-if="person.phone">{{ person.phone }}</a></td>
          <td class="whitespace-nowrap"><a :href="`mailto:${person.email}`" class="font-normal" v-if="person.email">{{ person.email }}</a></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      selectAll: {
        checked: false,
        indeterminate: false
      },
    }
  },
  computed: {
    ...mapState([
      'personnel',
      'sort'
    ]),
  },

  watch: {
    personnel: {
      deep: true,
      handler() {
        if (this.personnel.length === 0) {
          this.checked = false
          this.indeterminate = false
        }
        else {

          // collect first selected and first unselected
          let selected, unselected = false;
          for (let p of this.personnel) {
            if (!selected && p._meta.selected) {
              selected = true;
            }
            else if (!unselected && !p._meta.selected) {
              unselected = true;
            }
            if (selected && unselected) {
              break
            }
          }

          // any selected, but no unselected
          this.selectAll.checked = selected && !unselected
          // any selected and unselected
          this.selectAll.indeterminate = selected && unselected
        }
      }
    }
  },

  methods: {
    toggleSelectAll(value) {
      this.personnel.forEach(p => {
        p._meta.selected = value
      })
    },
    sortBy(key) {
      let order = 1

      // if same key, invert order
      if (this.sort.key === key) {
        order = -this.sort.order
      }

      this.$store.dispatch('updateOrder', { key, order })
    },
  },
}
</script>
