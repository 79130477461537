import axios from 'axios';

export default {
  getPersonnel(query, order) {
    return axios.get(Routes.personnel_index_path({ format: 'json' }), {
      params: {
        q: this._makeQueryArgument(query),
        o: this._makeOrderArgument(order),
      }
    })
  },

  _makeQueryArgument(query) {
    // const args = query.map(item => {
    //   return `${item.key}:${item.op}[${item.values.join(',')}]`
    // })
    // return args.join(',')

    return query.reduce((memo, item) => {
      memo[`${item.key}:${item.op}`] = item.values
      return memo
    }, {});
  },

  _makeOrderArgument({ key, order }) {
    return `${key}:${order < 0 ? 'desc' : 'asc'}`
  },
}
