// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require('chart.js')

import "@hotwired/turbo-rails"
import Vue from 'vue'

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

global.toastr = require('toastr')

import '../stylesheets/application'
import '../components/personnel'
import 'controllers'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.Routes = require('routes.js.erb')

Vue.mixin({
  data: function() {
    return {
      Routes: window.Routes
    }
  }
})

document.addEventListener("turbo:load", () => {
  tippy('[data-tippy-content]', {
    duration: 0,
    allowHTML: true
  })
})
